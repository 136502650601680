import invariant from 'tiny-invariant'
import {ChainId} from '../constants'
import {validateAndParseAddress} from '../utils'
import {Currency} from './currency'
import {DeploymentInfo} from '../config'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    DeploymentInfo[ChainId.RINKEBY].weth.proxyAddress,
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.EVMOSTEST]: new Token(ChainId.EVMOSTEST, '0x42CeDD53a09dCd6cd9482d9a6B75C8fdB2bA5132', 18, 'WTEVMOS', 'Wrapped TEVMOS'),
  [ChainId.EVMOS]: new Token(ChainId.EVMOS, '0xd4949664cd82660aae99bedc034a0dea8a0bd517', 18, 'WEVMOS', 'Wrapped EVMOS'),
  [ChainId.CELO]: new Token(ChainId.CELO, '0xe9eCec2cfA95D7df0644B6c8BaDD3BC1c3Cf39BA', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.CELOALFAJORES]: new Token(ChainId.CELOALFAJORES, '0xC8F2E555951a1eDb610b5bEBa530F4130E5d94F9', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ZKSYNCTEST]: new Token(ChainId.ZKSYNCTEST, '0xa1EA0B2354F5A344110af2b6AD68e75545009a03', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, '0xa1EA0B2354F5A344110af2b6AD68e75545009a03', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ZKSYNCSEPOLIATEST]: new Token(ChainId.ZKSYNCSEPOLIATEST, '0x863EC5643cEa4B4d8adE58757fe8EAB417F2B47d', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.SCROLLGOERLITEST]: new Token(ChainId.SCROLLGOERLITEST, '0xa1EA0B2354F5A344110af2b6AD68e75545009a03', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.SCROLLSEPOLIATEST]: new Token(ChainId.SCROLLSEPOLIATEST, '0x5300000000000000000000000000000000000004', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.BLASTSEPOLIA]: new Token(ChainId.BLASTSEPOLIA, '0x4200000000000000000000000000000000000023', 18, 'WETH', 'Wrapped Ether'),

}
